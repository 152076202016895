import React, { useState } from 'react';
import { useQuery } from 'hooks';
import { Button, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
	Wrapper,
	Block,
	Title,
	Text,
	Form,
	PhoneInput,
	ButtonWrapper,
} from './styles';

export type UserT = {
	name: string;
	phone: string;
};

export const Query: React.FC = () => {
	const [user, setUser] = useState<UserT>({
		name: '',
		phone: '',
	});

	const {
		status: { validationError, loading, sent },
		sendQuery,
	} = useQuery(user);

	return (
		<Wrapper>
			<Block>
				<Title>Узнайте наши расценки</Title>
				<Text>Введите свои данные для получения бесплатной консультации</Text>
				<Form>
					<ButtonWrapper>
						<Input
							disabled={sent}
							onChange={(e) => {
								e.persist();
								setUser((user) => ({ ...user, name: e.target.value }));
							}}
							style={{
								borderColor:
									validationError === 'Введите правильное имя' ? '#ff4d4f' : '',
							}}
							placeholder="Ваше имя"
							prefix={<UserOutlined />}
						/>
					</ButtonWrapper>
					<PhoneInput
						buttonStyle={{
							borderColor:
								validationError === 'Введите правильный номер' ? '#ff4d4f' : '',
						}}
						inputStyle={{
							borderColor:
								validationError === 'Введите правильный номер' ? '#ff4d4f' : '',
						}}
						placeholder="+375 (44) 111 11 11"
						country={'by'}
						value={user.phone}
						onChange={(e) => setUser((user) => ({ ...user, phone: e }))}
						disabled={sent}
					/>
					<ButtonWrapper>
						<Button
							onClick={sendQuery}
							type="primary"
							size="middle"
							danger={!sent}
							style={{
								background: sent ? 'rgb(102, 187, 106)' : '',
								borderColor: sent ? 'rgb(102, 187, 106)' : '',
							}}
							disabled={sent}
							block
							loading={loading}
						>
							{!sent ? 'Отправить' : 'Успешно отправлено...'}
						</Button>
					</ButtonWrapper>
				</Form>
			</Block>
		</Wrapper>
	);
};
