import { useState } from 'react';
import { message } from 'antd';
import { UserT } from '../components/Query';

type StatusT = {
	validationError: boolean | string;
	loading: boolean;
	sent: boolean;
};

export function useQuery(user: UserT) {
	const [status, setStatus] = useState<StatusT>({
		validationError: false,
		loading: false,
		sent: false,
	});

	const validation = (user: UserT) => {
		const { name, phone } = user;

		if (name.trim().length < 2) {
			return 'Введите правильное имя';
		}

		if (
			!phone.match(
				// eslint-disable-next-line no-useless-escape
				/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
			) ||
			phone.length < 12
		) {
			return 'Введите правильный номер';
		}

		return false;
	};

	const sendQuery = async () => {
		const isValid = validation(user);

		if (!isValid) {
			setStatus((prevStatus) => ({
				...prevStatus,
				validationError: false,
				loading: true,
			}));

			try {
				const res = await fetch(
					`https://plitkoklad-bot.onrender.com/client_request?phone=${user.phone}&name=${user.name}`,
					{
						headers: {
							Accept: 'application/json',
						},
						method: 'GET',
					}
				);

				const { error } = await res.json();

				if (error) {
					message.error('Произошла ошибка, повторите отправку');
					setStatus((prevStatus) => ({ ...prevStatus, sent: false }));
				} else {
					setStatus((prevStatus) => ({ ...prevStatus, sent: true }));
				}
			} catch (e) {
				message.error('Произошла ошибка, повторите отправку');
				setStatus((prevStatus) => ({ ...prevStatus, sent: false }));
			}
			setStatus((prevStatus) => ({ ...prevStatus, loading: false }));
		} else {
			message.error(isValid);
			setStatus((prevStatus) => ({ ...prevStatus, validationError: isValid }));
		}
	};

	return { status, sendQuery };
}
