import styled from 'styled-components';
import { devices } from 'styles';

export const Wrapper = styled.div`
	height: 25%;

	@media ${devices.mobile} {
		width: 100%;
		padding-top: 5px;
	}
	@media ${devices.tablet} {
		width: 100%;
		padding-top: 5px;
	}
	@media ${devices.laptop} {
		padding-top: 10px;
	}
`;

export const Content = styled.div`
	height: 100%;
	width: 100%;
	padding: 5px;
	background-color: #fff;

	:hover {
		box-shadow: 2px 2px 20px #000;
	}
`;

export const Title = styled.div`
	font-family: Bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 23px;
	line-height: 27px;
	color: #000;
	margin-bottom: 10px;
`;

export const Icon = styled.div`
	margin-right: 5px;
	display: flex;
	align-items: center;
`;

export const Item = styled.div`
	display: flex;
	align-items: center;
	background-color: #c51414;
	padding: 5px;
	color: #fff;
	font-family: Bitter;
	font-style: normal;
	font-weight: 200;
	font-size: 15px;
	margin: 5px;
	line-height: 20px;

	:hover {
		box-shadow: 2px 1px 0px #690a0a;
	}
`;
