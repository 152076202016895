import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader, Typography, Divider } from 'antd';
import { Wrapper, Content, Image } from './styles';
import { useStorage } from '../../firebase';

const { Title, Text } = Typography;

const GreenZoneDevice: React.FC = () => {
	const { gzdImages } = useStorage();

	const navigate = useNavigate();

	return (
		<Wrapper>
			<PageHeader
				onBack={() => navigate(-1)}
				title="Зелёная зона и её составляющие"
				subTitle="Plitkoklad"
			/>
			<Content>
				<Typography>
					<Image height={400} src={gzdImages[0]?.url} />
					<Title level={5}>
						Зелёная зона и дополнения к ней являются так же нужными фрагментами
						вашего участка.
					</Title>
					<Divider />
					<Title level={5}>Этот раздел стостоит из:.</Title>
					<Text>
						<ul>
							<li>Устройства газона</li>
							<li>Высадки деревьев, кустарников</li>
							<li>Установкой фонариков, и освещения</li>
							<li>Установка декоративных камней и устройство клумб</li>
						</ul>
					</Text>
					<div>
						<Image height={300} src={gzdImages[1]?.url} />
						<Image height={300} src={gzdImages[2]?.url} />
						<Image height={300} src={gzdImages[3]?.url} />
						<Image height={300} src={gzdImages[4]?.url} />
						<Image height={300} src={gzdImages[5]?.url} />
					</div>
				</Typography>
			</Content>
		</Wrapper>
	);
};

export default GreenZoneDevice;
