import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

const sizes = {
	mobile: '320px',
	tablet: '768px',
	laptop: '1024px',
};

export const devices = {
	mobile: `(min-width: ${sizes.mobile})`,
	tablet: `(min-width: ${sizes.tablet})`,
	laptop: `(min-width: ${sizes.laptop})`,
};

export const GlobalStyle = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		transition: .3s;
	}

	body {
		background-color: #e9e9e9;
	}

	#bg-image {
		height: 100%;
    width: 100%;
		z-index: -1;
		position: fixed;
		left: 0;
		right: 0;
		display: block;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		filter: blur(8px);
  	-webkit-filter: blur(8px);
	}

	#root {
		
	}

	.react-tel-input {
		width: 90% !important;
	}

	.form-control {
		width: 100% !important;
	}

	.react-photo-gallery--gallery {
		width: 99%;
	}

	.ant-upload-select-text {
		width: 100%;
	}

	.upload-item {
		input[type="file"] {
			display: none;
		}	

		label {
			border: 1px solid #ccc;
			display: inline-block;
			padding: 6px 12px;
			cursor: pointer;
			width: 100%;
			text-align: center;
		}
	}

	.uploaded-item {
		input[type="file"] {
			display: none;
		}	

		label {
			border: 1px solid rgb(102, 187, 106);
			display: inline-block;
			padding: 6px 12px;
			cursor: pointer;
			width: 100%;
			text-align: center;
		}
	}

	.error-item {
		input[type="file"] {
			display: none;
		}	

		label {
			border: 1px solid #ff4d4f;
			display: inline-block;
			padding: 6px 12px;
			cursor: pointer;
			width: 100%;
			text-align: center;
		}
	}
`;

export const Flex = styled.div<{ isHeight?: boolean }>`
	@media ${devices.laptop} {
		padding-top: 10px;
	}

	display: flex;

	${(p) => p.isHeight && 'height: 30%;'}

	@media ${devices.mobile} {
		flex-direction: column;
	}
	@media ${devices.tablet} {
		flex-direction: column;
	}
	@media ${devices.laptop} {
		flex-direction: row;
	}
`;

export const LeftColumn = styled.div`
	@media ${devices.mobile} {
		padding: 0 1% 0 1%;
	}
	@media ${devices.tablet} {
		padding: 0 1% 0 1%;
	}
	@media ${devices.laptop} {
		padding: 1% 0 0 1%;
	}

	flex-basis: 20%;
`;

export const RightColumn = styled.div`
	@media ${devices.mobile} {
		padding: 0 1% 0 1%;
	}
	@media ${devices.tablet} {
		padding: 0 1% 0 1%;
	}
	@media ${devices.laptop} {
		padding: 1% 1% 0 1%;
	}

	flex-basis: 80%;
	justify-content: center;
`;
