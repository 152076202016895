import styled from 'styled-components';
import { devices } from 'styles';

export const Wrapper = styled.div`
	height: 25%;

	@media ${devices.mobile} {
		padding-top: 5px;
	}
	@media ${devices.tablet} {
		padding-top: 5px;
	}
	@media ${devices.laptop} {
		padding-top: 0;
	}
`;

export const Block = styled.div`
	height: 100%;
	background-color: #fff;
	width: 100%;
	padding: 5px;

	:hover {
		box-shadow: 2px 2px 20px #000;
	}
`;

export const Title = styled.div`
	font-family: Bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 23px;
	line-height: 27px;
	color: #000;
`;

export const Div = styled.div`
	margin: 5px;
`;

export const Flex = styled.div`
	display: flex;
	background-color: #c51414;
	color: #fff;
	margin-bottom: 5px;
	font-size: 14px;
`;

export const Job = styled.div<{ isLink: boolean }>`
	flex-basis: ${(p) => (!p.isLink ? '100%' : '85%')};
	padding: 5px;
`;

export const Button = styled.div`
	flex-basis: 15%;
	color: #c51414;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #c51414;
	cursor: pointer;
`;
