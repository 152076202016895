import React from 'react';
import { Wrapper, Block, Title, Text } from './styles';

export const Main: React.FC = () => {
	return (
		<Wrapper>
			<Block>
				<span>
					<Title>Plitkoklad </Title>
					<Text>
						- мастера, занимающиеся благоустройством территорий, мы работаем для
						того, чтобы вы воплощали свои мечты, и становились владельцами
						красивых и стильных участков!
					</Text>
				</span>
			</Block>
		</Wrapper>
	);
};
