import React from 'react';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import InfoIcon from '@material-ui/icons/ReportProblem';
import SpeedIcon from '@material-ui/icons/Speed';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import { Wrapper, Content, Grid, Icon, Text, Block } from './styles';

export const Facts: React.FC = () => {
	return (
		<Wrapper>
			<Content>
				<Grid>
					<Block>
						<Icon>
							<MoneyOffIcon />
						</Icon>
						<Text>Бесплатный замер и консультация</Text>
					</Block>
					<Block>
						<Icon>
							<InfoIcon />
						</Icon>
						<Text>
							Помощь в подборе и заказе плитки и необходимых материалов
						</Text>
					</Block>
					<Block>
						<Icon>
							<SpeedIcon />
						</Icon>
						<Text>Быстрые сроки выполнения</Text>
					</Block>
					<Block>
						<Icon>
							<AccessibilityIcon />
						</Icon>
						<Text>Индивидуальный подход к клиенту</Text>
					</Block>
					<Block>
						<Icon>
							<ThumbUpAltIcon />
						</Icon>
						<Text>Гарантии качества</Text>
					</Block>
					<Block>
						<Icon>
							<VerifiedUser />
						</Icon>
						<Text>Только проверенные технологии</Text>
					</Block>
				</Grid>
			</Content>
		</Wrapper>
	);
};
