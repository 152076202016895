import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Wrapper, Content, Img } from './styles';
import { useStorage } from '../../firebase';

export const Carousel: React.FC = () => {
	const [index, setIndex] = useState<number>(0);
	const { images } = useStorage();

	const photos = useMemo(() => {
		const names = Array.from({ length: 7 }).map((_, idx) => `${idx + 7}.jpg`);
		const imgs = images.filter((image) => names.includes(image.name));

		return imgs.map((img) => img.url);
	}, [images]);

	const nextIndex = useCallback(() => {
		setIndex((prevIndex) => {
			if (prevIndex < photos.length - 1) return (prevIndex += 1);
			return 0;
		});
	}, [photos.length]);

	const timeouts = () => {
		setTimeout(() => {
			const img = document.getElementById('img');
			img!.style.opacity = '0.5';
			setTimeout(() => {
				img!.style.opacity = '0.25';
				setTimeout(() => {
					img!.style.opacity = '0';
					setTimeout(() => {
						img!.style.opacity = '1';
					}, 1000);
				}, 500);
			}, 500);
		}, 2500);
	};

	useEffect(() => {
		if (photos.length === 7) {
			timeouts();
			const interval = setInterval(() => {
				nextIndex();
				timeouts();
			}, 4000);

			return () => {
				clearInterval(interval);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [photos]);

	return (
		<Wrapper>
			<Content>
				<Img id="img" src={photos[index]} alt="" />
			</Content>
		</Wrapper>
	);
};
