import styled from 'styled-components';
import { devices } from 'styles';

export const Wrapper = styled.div`
	height: 20%;
	border: 10px solid black;
	border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 15s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23fff' /%3E%3Cstop offset='25%25' stop-color='%23fff' /%3E%3Cstop offset='50%25' stop-color='%23ccc' /%3E%3Cstop offset='100%25' stop-color='%23000' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E")
		1;
`;

export const Block = styled.div`
	@media ${devices.mobile} {
		width: 100%;
	}
	@media ${devices.tablet} {
		width: 100%;
	}
	@media ${devices.laptop} {
		width: 100%;
	}

	color: #000;
	font-family: 'Russo One', sans-serif;

	display: flex;
	align-items: center;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.4);

	height: 100%;
	padding: 10px;
`;

export const Title = styled.span`
	font-weight: bold;
	font-size: 40px;
	color: #c51315;
`;

export const Text = styled.span`
	font-size: 30px;
`;
