import styled from 'styled-components';
import { devices } from 'styles';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

export const FormWrapper = styled.div`
	@media ${devices.mobile} {
		width: 90%;
	}
	@media ${devices.tablet} {
		width: 70%;
	}
	@media ${devices.laptop} {
		width: 40%;
	}
`;

export const Center = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
