export function useServices() {
	const services = [
		{ text: 'Укладка тротуарной плитки', link: 'laying-paving-slabs' },
		{ text: 'Установка бордюров', link: 'installation-of-curbs' },
		{ text: 'Устройство зелёной зоны', link: 'green-zone-device' },
		{ text: 'Укладка камня, песчаника', link: 'stone-laying-sandstone' },
		{
			text: 'Монтаж ливневых коммуникаций',
			link: 'installation-of-storm-communications',
		},
		{ text: 'Установка ступеней' },
		{ text: 'Утрамбовка ПГС, гравия и других смесей' },
	];

	return { services };
}
