import React, { useState, useEffect } from 'react';
import { Button, Spin, Empty, List, Rate, Image } from 'antd';
import {
	LikeOutlined,
	DislikeOutlined,
	UnorderedListOutlined,
	DeleteOutlined,
} from '@ant-design/icons';
import { useLocalStorage } from 'hooks';
import { useAuth, useData } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { WrapperLoader, WrapperContent, Card, PageHeader } from './styles';
import { ActiveTabKeyT } from './types';

const StatusKeys = {
	waiting: 'waiting',
	allowed: 'allowed',
	canceled: 'canceled',
};

export const AdminPage: React.FC = () => {
	const [activeTabKey, setActiveTabKey] = useState<ActiveTabKeyT>('waiting');
	const navigate = useNavigate();
	const { signOut } = useAuth();
	const {
		reviews: { data, loading },
		changeStatusReview,
		deleteReview,
	} = useData();
	const { isUser } = useLocalStorage();

	const tabListNoTitle = [
		{
			key: StatusKeys.waiting,
			tab: (
				<div>
					<UnorderedListOutlined />({data.waiting.length})
				</div>
			),
		},
		{
			key: StatusKeys.allowed,
			tab: (
				<div>
					<LikeOutlined />({data.allowed.length})
				</div>
			),
		},
		{
			key: StatusKeys.canceled,
			tab: (
				<div>
					<DislikeOutlined />({data.canceled.length})
				</div>
			),
		},
	];

	const buttons = (id: string, paths: string[]) => {
		if (activeTabKey === StatusKeys.waiting) {
			return [
				<Button
					style={{
						background: 'rgb(102, 187, 106)',
						borderColor: 'rgb(102, 187, 106)',
						color: '#fff',
					}}
					onClick={() => changeStatusReview(id, StatusKeys.allowed)}
				>
					<LikeOutlined />
				</Button>,
				<Button
					type="primary"
					onClick={() => changeStatusReview(id, StatusKeys.canceled)}
				>
					<DislikeOutlined />
				</Button>,
				<Button danger type="primary" onClick={() => deleteReview(id, paths)}>
					<DeleteOutlined />
				</Button>,
			];
		} else if (activeTabKey === StatusKeys.allowed) {
			return [
				<Button
					type="primary"
					onClick={() => changeStatusReview(id, StatusKeys.canceled)}
				>
					<DislikeOutlined />
				</Button>,
				<Button danger type="primary" onClick={() => deleteReview(id, paths)}>
					<DeleteOutlined />
				</Button>,
			];
		} else if (activeTabKey === StatusKeys.canceled) {
			return [
				<Button
					style={{
						background: 'rgb(102, 187, 106)',
						borderColor: 'rgb(102, 187, 106)',
						color: '#fff',
					}}
					onClick={() => changeStatusReview(id, StatusKeys.allowed)}
				>
					<LikeOutlined />
				</Button>,
				<Button danger type="primary" onClick={() => deleteReview(id, paths)}>
					<DeleteOutlined />
				</Button>,
			];
		}
	};

	useEffect(() => {
		if (!isUser()) {
			navigate('/auth');
		}

		const root = document.getElementById('root');
		root!.style.height = '100%';

		document.body.style.backgroundColor = '#fff';
		document.title = 'Plitkoklad | Администратор';

		return () => {
			const root = document.getElementById('root');
			root!.style.height = '';
		};
	}, [isUser, navigate]);

	return (
		<>
			<PageHeader
				title="Plitkoklad"
				subTitle="Администратор"
				extra={[
					<Button key="first" danger type="dashed" onClick={signOut}>
						Выйти
					</Button>,
				]}
			/>
			{loading ? (
				<WrapperLoader>
					<Spin size="large" />
				</WrapperLoader>
			) : (
				<WrapperContent>
					<Card
						tabList={tabListNoTitle}
						activeTabKey={activeTabKey}
						onTabChange={(key) => setActiveTabKey(key as ActiveTabKeyT)}
					>
						{data[activeTabKey].length ? (
							<List
								itemLayout="vertical"
								dataSource={data[activeTabKey]}
								renderItem={(item) => {
									const paths: string[] = [];

									if (item.images) {
										item.images.forEach((img) => paths.push(img.path));
									}

									return (
										<List.Item actions={buttons(item.id, paths)}>
											{item.images &&
												item.images.map((img, idx) => (
													<Image height={200} src={img.url} key={idx} />
												))}
											<List.Item.Meta
												title={item.name}
												description={item.text}
											/>
											<Rate value={item.rating} disabled />
										</List.Item>
									);
								}}
							/>
						) : (
							<Empty description="Здесь пока ничего нет..." />
						)}
					</Card>
				</WrapperContent>
			)}
		</>
	);
};
