import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, PageHeader, Typography } from 'antd';
import { Wrapper, Content, Image } from './styles';
import { useStorage } from '../../firebase';

const { Title, Text } = Typography;

const LayingPavingSlabs: React.FC = () => {
	const { iocImages } = useStorage();

	const navigate = useNavigate();

	return (
		<Wrapper>
			<PageHeader
				onBack={() => navigate(-1)}
				title="Установка бордюров и их разновидность"
				subTitle="Plitkoklad"
			/>
			<Content>
				<Typography>
					<Image height={400} src={iocImages[0]?.url} />
					<Title level={5}>Существует несколько категорий бардюров:</Title>
					<ul>
						<li>Тротуарный</li>
						<li>Монолитный</li>
						<li>Дорожный</li>
					</ul>
					<Divider />
					<Title level={5}>
						Необходимость установки нужного бордюра определяется назначением
						места, на котором он будет установлен.
					</Title>

					<ul>
						<li>
							Тротуарный Бордюр в основном имеет высоту 20см, и обеспечивает
							жёсткую опору для плитки. В случае наезда техники на край плитки,
							правильно установленный бордюр не даст ей уехать с основной
							площадки.
						</li>
						<li>
							Монолитный бордюр больше подходит в пешеходных зонах, где давления
							на края минимальное, и прочности будет достаточно без тротуарного
							борта. Преимущество монолитного бордюра в том что это будет стоить
							дешевле, и при посеве газона, он будет расти вплотную к плитке.
						</li>
						<li>
							Дорожный бордюр устанавливается в основном в торцевой части
							площадки, для заезда автомобиля на плитку. Он имеет усиленную
							толщину и конструкцию, и может брать на себя большую нагрузку. Так
							же устанавливается там где будет ездить тяжёлая техника и
							грузовики.
						</li>
					</ul>
					<Text>
						Plitkoklad устанавливает бордюры на крепкий бетон, утрамбовывая его
						резиновым молотком, затем делается заливка бетоном с двух сторон для
						достижения максимальной прочности.
					</Text>
					<div>
						<Image height={400} src={iocImages[1]?.url} />
						<Image height={400} src={iocImages[2]?.url} />
						<Image height={400} src={iocImages[3]?.url} />
					</div>
				</Typography>
			</Content>
		</Wrapper>
	);
};

export default LayingPavingSlabs;
