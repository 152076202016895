import styled from 'styled-components';
import { devices } from 'styles';
import { Card as AntCard, PageHeader as PageHeaderAnt } from 'antd';

export const PageHeader = styled(PageHeaderAnt)`
	height: 10%;
`;

export const WrapperLoader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 90%;
`;

export const WrapperContent = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: 90%;
`;

export const Card = styled(AntCard)`
	height: 100%;

	@media ${devices.mobile} {
		width: 100%;
	}

	@media ${devices.tablet} {
		width: 95%;
	}

	@media ${devices.laptop} {
		width: 90%;
	}
`;
