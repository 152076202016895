import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useServices } from 'hooks';
import { Wrapper, Block, Title, Div, Job, Button, Flex } from './styles';

export const JobTypes: React.FC = () => {
	const navigate = useNavigate();
	const { services } = useServices();

	return (
		<Wrapper>
			<Block>
				<Title>Виды работ</Title>
				<Div>
					{services.map((service) => (
						<Flex key={service.text}>
							<Job isLink={!!service.link}>{service.text}</Job>
							{service.link && (
								<Button onClick={() => navigate(service.link)}>
									<ArrowForwardIosIcon />
								</Button>
							)}
						</Flex>
					))}
				</Div>
			</Block>
		</Wrapper>
	);
};
