import styled from 'styled-components';
import { devices } from 'styles';

export const Wrapper = styled.div`
	background-color: #fff;
	padding: 1% 3% 1% 3%;
`;

export const Img = styled.img`
	@media ${devices.mobile} {
		height: 20px;
	}
	@media ${devices.tablet} {
		height: 30px;
	}
	@media ${devices.laptop} {
		height: 40px;
	}
`;

export const MediaFlex = styled.div`
	display: flex;

	@media ${devices.mobile} {
		flex-direction: column;
		justify-content: center;
	}
	@media ${devices.tablet} {
		flex-direction: column;
		justify-content: center;
	}
	@media ${devices.laptop} {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
`;

export const Flex = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const Title = styled.div`
	font-family: 'Bitter', serif;
	font-style: normal;
	font-weight: bold;
	color: #000;

	@media ${devices.mobile} {
		font-size: 20px;
		line-height: 30px;
	}
	@media ${devices.tablet} {
		font-size: 30px;
		line-height: 40px;
	}
	@media ${devices.laptop} {
		font-size: 40px;
		line-height: 50px;
	}
`;

export const Text = styled.div<{ borderBottom?: boolean; small?: boolean }>`
	font-family: 'Bitter', serif;
	font-style: normal;
	font-weight: 300;
	color: #000;

	${(p) => p.borderBottom && 'border-bottom: 2px solid #c51414'};

	@media ${devices.mobile} {
		${(p) => (p.small ? 'font-size: 10px' : 'font-size: 12px')};
		${(p) => p.borderBottom && 'border-bottom: 1px solid #c51414'};
	}
	@media ${devices.tablet} {
		${(p) => (p.small ? 'font-size: 15px' : 'font-size: 20px')};
	}
	@media ${devices.laptop} {
		${(p) => (p.small ? 'font-size: 15px' : 'font-size: 20px')};
	}
`;
