import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Wrapper, Content, Title, Item, Icon } from './styles';

export const Addiction: React.FC = () => {
	return (
		<Wrapper>
			<Content>
				<Title>Цена зависит от:</Title>
				<Item>
					<Icon>
						<CheckCircleIcon />
					</Icon>
					Площади укладки
				</Item>
				<Item>
					<Icon>
						<CheckCircleIcon />
					</Icon>
					Вида почвы
				</Item>
				<Item>
					<Icon>
						<CheckCircleIcon />
					</Icon>
					Подготовки поверхности
				</Item>
				<Item>
					<Icon>
						<CheckCircleIcon />
					</Icon>
					Вида плитки
				</Item>
				<Item>
					<Icon>
						<CheckCircleIcon />
					</Icon>
					Вида раскладки (рисунок, цвет, выкладка)
				</Item>
				<Item>
					<Icon>
						<CheckCircleIcon />
					</Icon>
					Назначения (пешеходная, ступеньки и т.д.)
				</Item>
			</Content>
		</Wrapper>
	);
};
