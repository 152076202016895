import React, { useState, useEffect, useMemo } from 'react';
import { useData } from '../../firebase';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Comment, Rate, Empty, Image } from 'antd';
import {
	Wrapper,
	Block,
	Title,
	Flex,
	EmptyWrapper,
	IconWrapper,
	CommentWrapper,
	TextName,
} from './styles';

export const Reviews: React.FC = () => {
	const [index, setIndex] = useState<number | null>(null);
	const {
		reviews: {
			data: { allowed },
		},
	} = useData();

	const allowedIndex = useMemo(() => {
		if (index === null) return null;
		return allowed[index];
	}, [index, allowed]);

	useEffect(() => {
		if (allowed[0]) {
			setIndex(0);
		}
	}, [allowed]);

	const prevIndex = () => {
		if (index === null) return;

		if (index > 0) {
			setIndex((prevIndex) => (prevIndex as number) - 1);
		}
	};

	const nextIndex = () => {
		if (index === null) return;

		if (index < allowed.length - 1) {
			setIndex((prevIndex) => (prevIndex as number) + 1);
		}
	};

	return (
		<Wrapper>
			<Block>
				<Title>Отзывы клиентов:</Title>
				{allowedIndex ? (
					<Flex>
						<IconWrapper onClick={prevIndex} pointer={index !== 0}>
							<ChevronLeftIcon color={index === 0 ? 'disabled' : 'inherit'} />
						</IconWrapper>
						<CommentWrapper>
							<Comment
								key={allowedIndex.id}
								author={<TextName>{allowedIndex.name}</TextName>}
								content={<p>{allowedIndex.text}</p>}
								datetime={<Rate value={allowedIndex.rating} disabled />}
							/>
							{allowedIndex.images &&
								allowedIndex.images.map((img, idx) => (
									<Image height={200} src={img.url} key={idx} />
								))}
						</CommentWrapper>
						<IconWrapper
							onClick={nextIndex}
							pointer={index !== allowed.length - 1}
						>
							<ChevronRightIcon
								color={index === allowed.length - 1 ? 'disabled' : 'inherit'}
							/>
						</IconWrapper>
					</Flex>
				) : (
					<EmptyWrapper>
						<Empty description={<TextName>Отзывов пока нет...</TextName>} />
					</EmptyWrapper>
				)}
			</Block>
		</Wrapper>
	);
};
