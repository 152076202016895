import React, { useMemo } from 'react';
import { useData } from '../../firebase';
import { Rate } from 'antd';
import { Wrapper, Block, Title, Flex, RateText } from './styles';

export const Rating: React.FC = () => {
	const {
		reviews: {
			data: { allowed },
		},
	} = useData();

	const rate = useMemo(() => {
		if (!allowed.length) return 5;

		const sum = allowed.reduce((acc, item) => acc + item.rating, 0);
		return Math.round(sum / allowed.length);
	}, [allowed]);

	return (
		<Wrapper>
			<Block>
				<Title>Средняя оценка:</Title>
				<Flex>
					<RateText>{rate.toFixed(2)}</RateText>
					<Rate value={rate} disabled />
				</Flex>
			</Block>
		</Wrapper>
	);
};
