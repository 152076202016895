import React, { useMemo } from 'react';
import { useStorage } from '../../firebase';
import Gallery from 'react-photo-gallery';
import { Wrapper, Content } from './styles';

function getSize(idx: number) {
	const pairs = [
		{ width: 3, height: 4 },
		{ width: 4, height: 3 },
		{ width: 2, height: 2 },
		{ width: 1, height: 1 },
		{ width: 2, height: 2 },
		{ width: 3, height: 4 },
	];

	return pairs[idx];
}

export const Photos: React.FC = () => {
	const { images } = useStorage();

	const photos = useMemo(() => {
		const names = Array.from({ length: 6 }).map((_, idx) => `${idx + 1}.jpg`);
		return images
			.filter((image) => names.includes(image.name))
			.map((img, idx) => {
				const { width, height } = getSize(idx);
				return {
					src: img.url,
					width,
					height,
				};
			});
	}, [images]);

	return (
		<Wrapper>
			<Content>
				<Gallery photos={photos} direction="row" />
			</Content>
		</Wrapper>
	);
};
