import styled from 'styled-components';
import { devices } from 'styles';

export const Wrapper = styled.div`
	height: 15%;

	@media ${devices.mobile} {
		padding-top: 5px;
	}
	@media ${devices.tablet} {
		padding-top: 5px;
	}
	@media ${devices.laptop} {
		padding-top: 10px;
	}
`;

export const Block = styled.div`
	height: 100%;
	background-color: #fff;
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	:hover {
		box-shadow: 2px 2px 20px #000;
	}
`;

export const Title = styled.div`
	font-family: Bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 25px;
	line-height: 30px;
	color: #000;
	text-align: center;
`;

export const RateText = styled.div`
	margin: 5px 15px 5px 5px;
	font-family: Bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 30px;
	line-height: 35px;
	color: #000;
	text-align: center;
`;

export const Flex = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
