export function useLocalStorage() {
	const saveUser = (userId: string) => {
		localStorage.setItem('__user', userId);
	};

	const dropUser = () => {
		localStorage.removeItem('__user');
	};

	const isUser = () => {
		const user = localStorage.getItem('__user');

		if (user) {
			return true;
		}

		return false;
	};

	return { saveUser, isUser, dropUser };
}
