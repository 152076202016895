import styled from 'styled-components';
import { devices } from 'styles';

export const Wrapper = styled.div`
	@media ${devices.mobile} {
		width: 100%;
		padding-top: 5px;
	}
	@media ${devices.tablet} {
		width: 100%;
		padding-top: 5px;
	}
	@media ${devices.laptop} {
		padding-top: 0;
		flex-basis: 70%;
		padding-left: 10px;
	}
`;

export const Content = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #c51414;
	padding: 5px;

	:hover {
		box-shadow: 2px 2px 20px #000;
	}
`;

export const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 33.33%;
	align-items: center;
	justify-items: center;
	width: 100%;
	height: 100%;
`;

export const Icon = styled.div`
	@media ${devices.mobile} {
		transform: scale(1);
		margin: 0;
	}
	@media ${devices.tablet} {
		transform: scale(1);
		margin: 0;
	}
	@media ${devices.laptop} {
		transform: scale(1.5);
		margin-bottom: 5px;
	}
`;

export const Text = styled.h1`
	font-family: 'Yanone Kaffeesatz', sans-serif;
	font-weight: bold;
	color: #fff;
	text-align: center;

	@media ${devices.mobile} {
		font-size: 15px;
		line-height: 20px;
	}
	@media ${devices.tablet} {
		font-size: 20px;
		line-height: 25px;
	}
	@media ${devices.laptop} {
		font-size: 30px;
		line-height: 35px;
	}
`;

export const Block = styled.div`
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	border: 2px solid #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	margin: 10%;

	:hover {
		box-shadow: 5px 4px 0px #fff;
	}

	@media ${devices.mobile} {
		flex-direction: column;
	}
	@media ${devices.tablet} {
		flex-direction: column;
	}
	@media ${devices.laptop} {
		flex-direction: column;
	}
`;
