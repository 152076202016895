import styled from 'styled-components';
import { devices } from 'styles';

export const Wrapper = styled.div`
	@media ${devices.mobile} {
		margin-top: 5%;
	}
	@media ${devices.tablet} {
		margin-top: 5%;
	}
	@media ${devices.laptop} {
		margin-top: 20%;
	}

	height: 10%;
	padding: 1% 3% 1% 3%;
`;

export const Flex = styled.div`
	height: 100%;
	display: flex;

	@media ${devices.mobile} {
		flex-direction: column;
		justify-content: center;
	}
	@media ${devices.tablet} {
		flex-direction: column;
		justify-content: center;
	}
	@media ${devices.laptop} {
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
`;

export const Title = styled.div`
	font-family: 'Bitter', serif;
	font-style: normal;
	font-weight: bold;
	color: #000;
	flex-basis: 15%;

	@media ${devices.mobile} {
		font-size: 20px;
		line-height: 30px;
	}
	@media ${devices.tablet} {
		font-size: 30px;
		line-height: 40px;
	}
	@media ${devices.laptop} {
		font-size: 40px;
		line-height: 50px;
	}
`;

export const Text = styled.div`
	font-family: 'Bitter', serif;
	font-style: normal;
	font-weight: 300;
	color: #000;

	@media ${devices.mobile} {
		border-left: 1px solid #c51414;
		border-bottom: 1px solid #c51414;
		font-size: 15px;
		height: 20px;
		padding: 0 10px 0 5px;
	}
	@media ${devices.tablet} {
		border-left: 1px solid #c51414;
		border-bottom: 1px solid #c51414;
		font-size: 15px;
		height: 20px;
		padding: 0 10px 0 5px;
	}
	@media ${devices.laptop} {
		border-left: 2px solid #c51414;
		border-bottom: 2px solid #c51414;
		font-size: 15px;
		height: 25px;
	}
`;

export const InfoText = styled.div`
	font-family: 'Bitter', serif;
	font-style: normal;
	font-weight: 500;
	color: #000;

	@media ${devices.mobile} {
		border-left: 1px solid #c51414;
		border-right: 1px solid #c51414;
		border-top: 1px solid #c51414;
		font-size: 15px;
		height: 20px;
		padding: 0 10px 0 5px;
	}
	@media ${devices.tablet} {
		border-left: 1px solid #c51414;
		border-right: 1px solid #c51414;
		border-top: 1px solid #c51414;
		font-size: 10px;
		height: 20px;
		padding: 0 10px 0 5px;
	}
	@media ${devices.laptop} {
		border-left: 2px solid #c51414;
		border-right: 2px solid #c51414;
		border-top: 2px solid #c51414;
		font-size: 15px;
		height: 25px;
	}
`;
