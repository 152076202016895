import React, { useState } from 'react';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import CloseIcon from '@material-ui/icons/Close';
import TelegramIcon from '@material-ui/icons/Telegram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ViberIcon from './svg/viber.svg';
import { Wrapper, Wave1, Wave2, Icon, MenuWrapper, MenuIcon } from './styles';

export const Phone: React.FC = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const changeIsOpen = () => setIsOpen((prevState) => !prevState);

	return (
		<Wrapper>
			{isOpen ? (
				<MenuWrapper>
					<MenuIcon href="viber://chat?number=%2B375333728226" target="_blank">
						<img src={ViberIcon} alt="viber" loading="lazy" />
					</MenuIcon>
					<MenuIcon href="https://wa.me/375333728226?lang=ru" target="_blank">
						<WhatsAppIcon htmlColor="rgb(102, 187, 106)" />
					</MenuIcon>
					<MenuIcon href="https://t.me/Rusavuk_Maks" target="_blank">
						<TelegramIcon htmlColor="#0088cc" />
					</MenuIcon>
					<MenuIcon href="tel:+375333728226" target="_blank">
						<LocalPhoneIcon htmlColor="rgb(102, 187, 106)" />
					</MenuIcon>
					<MenuIcon onClick={changeIsOpen}>
						<CloseIcon color="disabled" />
					</MenuIcon>
				</MenuWrapper>
			) : (
				<div onClick={changeIsOpen}>
					<Wave1 />
					<Wave2 />
					<Icon>
						<LocalPhoneIcon color="disabled" />
					</Icon>
				</div>
			)}
		</Wrapper>
	);
};
