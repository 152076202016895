import React, { useState } from 'react';
import { useAddReview } from 'hooks';
import { Input, Button, Rate, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Wrapper, Block, Title, FormWrapper, Form } from './styles';

const { TextArea } = Input;

export const AddReview: React.FC = () => {
	const [file, setFile] = useState<File | undefined>(undefined);
	const {
		status: { loading, sent },
		addReview,
	} = useAddReview();

	const callAddReview = (values: any) => {
		const { username, review, rate } = values;
		addReview(username, review, rate, file);
	};

	const bytesToMegaBytes = (bytes: number) => bytes / 1024 ** 2;

	const handleFile = (e: any) => {
		e.preventDefault();
		e.persist();

		const file = e.target.files[0];
		const wrapper: any = document.getElementById('upload-wrapper');

		const input: any = document.getElementById('input');

		const megaBytes = bytesToMegaBytes(file.size);
		const fileType = ['image/jpeg', 'image/png', 'image/jpg'].includes(
			file.type
		);

		if (megaBytes > 5) {
			message.error('Размер файла не должен превышать 5 мегабайт');
			input!.value = '';
			wrapper.className = 'error-item';
			return;
		}

		if (!fileType) {
			message.error(`${file.name} имеет неверный формат`);
			input!.value = '';
			wrapper.className = 'error-item';
			return;
		}

		setFile(file);
		wrapper.className = 'uploaded-item';
	};

	return (
		<Wrapper>
			<Block>
				<Title>Оставить отзыв:</Title>
				<FormWrapper>
					<Form
						name="normal_login"
						className="login-form"
						onFinish={(values) => callAddReview(values)}
					>
						<Form.Item
							name="username"
							rules={[
								{ required: true, message: 'Пожалуйста, введите ваше имя!' },
							]}
						>
							<Input
								disabled={sent}
								prefix={<UserOutlined className="site-form-item-icon" />}
								placeholder="Ваше Имя"
							/>
						</Form.Item>
						<Form.Item
							name="review"
							rules={[
								{ required: true, message: 'Пожалуйста, оставьте отзыв...' },
							]}
						>
							<TextArea
								disabled={sent}
								style={{ minHeight: '140px' }}
								placeholder="Отзыв..."
								size="large"
								autoSize={{ maxRows: 5, minRows: 5 }}
							/>
						</Form.Item>
						<Form.Item>
							<div id="upload-wrapper" className="upload-item">
								<label>
									<input
										type="file"
										id="input"
										onChange={handleFile}
										disabled={sent}
									/>
									{file ? `${file.name.substr(0, 20)}...` : 'Загрузить файл'}
								</label>
							</div>
						</Form.Item>
						<Form.Item
							name="rate"
							rules={[
								{ required: true, message: 'Пожалуйста, оставьте рейтинг...' },
							]}
						>
							<Rate disabled={sent} />
						</Form.Item>
						<Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								htmlType="submit"
								type="primary"
								danger={!sent}
								size="middle"
								style={{
									background: sent ? 'rgb(102, 187, 106)' : '',
									borderColor: sent ? 'rgb(102, 187, 106)' : '',
								}}
								disabled={sent}
								block
								loading={loading}
							>
								{sent ? 'Отзыв оставлен' : 'Оставить отзыв'}
							</Button>
						</Form.Item>
					</Form>
				</FormWrapper>
			</Block>
		</Wrapper>
	);
};
