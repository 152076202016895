import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './fonts.css';
import 'antd/dist/antd.css';
import 'react-phone-input-2/lib/style.css';
import { GlobalStyle } from './styles';

ReactDOM.render(
	<>
		<GlobalStyle />
		<App />
	</>,
	document.getElementById('root')
);
