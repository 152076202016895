import styled from 'styled-components';
import { Image as Img } from 'antd';

export const Wrapper = styled.div`
	background-color: #fff;
	margin: 1%;
`;

export const Content = styled.div`
	padding: 1%;
`;

export const Div = styled.div``;

export const Image = styled(Img)`
	border: 1px solid #000;
`;
