import { useStorage } from './firebase';
import React, { useState, useEffect } from 'react';
import LayingPavingSlabs from './pages/LayingPavingSlabs';
import GreenZoneDevice from './pages/GreenZoneDevice';
import StoneLayingSandstone from './pages/StoneLayingSandstone';
import InstallationOfCurbs from './pages/InstallationOfCurbs';
import InstallationOfStormCommunications from './pages/InstallationOfStormCommunications';
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import {
	Header,
	Phone,
	JobTypes,
	Photos,
	Query,
	AuthForm,
	AddReview,
	AdminPage,
	Rating,
	Reviews,
	Footer,
	Carousel,
	Facts,
	Addiction,
	Main,
} from './components';
import { Flex, LeftColumn, RightColumn } from './styles';

function MainPage() {
	const [width, setWidth] = useState<number>(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);

		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const device = width >= 1024 ? 'laptop' : width >= 768 ? 'tablet' : 'mobile';

	return (
		<>
			<Phone />
			<Header />
			{device === 'laptop' ? (
				<Flex>
					<LeftColumn>
						<JobTypes />
						<Addiction />
						<Query />
						<AddReview />
						<Rating />
					</LeftColumn>
					<RightColumn>
						<Main />
						<Flex isHeight>
							<Carousel />
							<Facts />
						</Flex>
						<Photos />
						<Reviews />
					</RightColumn>
				</Flex>
			) : (
				<Flex>
					<RightColumn>
						<Main />
						<Carousel />
						<Facts />
						<Photos />
						<JobTypes />
						<Addiction />
					</RightColumn>
					<LeftColumn>
						<Query />
						<AddReview />
						<Reviews />
						<Rating />
					</LeftColumn>
				</Flex>
			)}
			<Footer />
		</>
	);
}

function AuthRoute() {
	return <AuthForm />;
}

function AdminRoute() {
	return <AdminPage />;
}

function AppWrapper(props: { children: React.ReactElement<any, any> }) {
	return (
		<>
			<Phone />
			<Header />
			{props.children}
			<Footer />
		</>
	);
}

function App() {
	const { images } = useStorage();

	useEffect(() => {
		const bg = images.find((img) => img.name === 'bg.webp');
		const root = document.getElementById('bg-image');

		if (root?.style && bg?.url) {
			root!.style.backgroundImage = `url(${bg?.url})`;
		}
	}, [images]);

	return (
		<>
			<div id="bg-image"></div>
			<Router>
				<Routes>
					<Route>
						<Route index element={<MainPage />} />
						<Route
							path="/laying-paving-slabs"
							element={
								<AppWrapper>
									<LayingPavingSlabs />
								</AppWrapper>
							}
						/>
						<Route
							path="/installation-of-curbs"
							element={
								<AppWrapper>
									<InstallationOfCurbs />
								</AppWrapper>
							}
						/>
						<Route
							path="/green-zone-device"
							element={
								<AppWrapper>
									<GreenZoneDevice />
								</AppWrapper>
							}
						/>
						<Route
							path="/stone-laying-sandstone"
							element={
								<AppWrapper>
									<StoneLayingSandstone />
								</AppWrapper>
							}
						/>
						<Route
							path="/installation-of-storm-communications"
							element={
								<AppWrapper>
									<InstallationOfStormCommunications />
								</AppWrapper>
							}
						/>
						<Route path="/auth" element={<AuthRoute />} />
						<Route path="/admin" element={<AdminRoute />} />
					</Route>
				</Routes>
			</Router>
		</>
	);
}

export default App;
