import styled from 'styled-components';
import PhoneInput2 from 'react-phone-input-2';
import { devices } from 'styles';

export const Wrapper = styled.div`
	height: 20%;

	@media ${devices.mobile} {
		padding-top: 5px;
	}
	@media ${devices.tablet} {
		padding-top: 5px;
	}
	@media ${devices.laptop} {
		padding-top: 10px;
	}
`;

export const Block = styled.div`
	height: 100%;
	background-color: #fff;
	width: 100%;
	padding: 10px;

	:hover {
		box-shadow: 2px 2px 20px #000;
	}
`;

export const Title = styled.div`
	font-family: Bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 23px;
	line-height: 27px;
	color: #000;
	text-align: center;
`;

export const Text = styled.div`
	font-family: Bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #595b59;
`;

export const Form = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

export const PhoneInput = styled(PhoneInput2)``;

export const ButtonWrapper = styled.div`
	margin: 10px 0 5px 0;
	width: 90%;
`;
