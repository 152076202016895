import React, { useEffect } from 'react';
import { useAuth, useStorage } from '../../firebase';
import { useLocalStorage } from 'hooks';
import { Form, Input, Button, Avatar } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Wrapper, FormWrapper, Center } from './styles';
import { useNavigate } from 'react-router-dom';

export const AuthForm: React.FC = () => {
	const navigate = useNavigate();
	const { isUser } = useLocalStorage();
	const { signIn } = useAuth();
	const { images } = useStorage();

	const logo = images.find((image) => image.name === 'logo.png')?.url;

	const doAuth = (values: any) => {
		const { username, password } = values;
		signIn(username, password);
	};

	useEffect(() => {
		if (isUser()) {
			navigate('/admin');
		}

		const root = document.getElementById('root');
		root!.style.height = '100%';

		document.body.style.backgroundColor = '#fff';
		document.title = 'Plitkoklad | Вход';
	}, [isUser, navigate]);

	useEffect(() => {
		return () => {
			const root = document.getElementById('root');
			root!.style.height = '';
		};
	}, []);

	return (
		<Wrapper>
			<FormWrapper>
				<Center>
					<Avatar
						style={{ marginBottom: '20px' }}
						shape="square"
						src={logo}
						size={96}
					/>
				</Center>
				<Form name="normal_login" className="login-form" onFinish={doAuth}>
					<Form.Item
						name="username"
						rules={[{ required: true, message: 'Пожалуйста введите логин!' }]}
					>
						<Input
							prefix={<UserOutlined className="site-form-item-icon" />}
							placeholder="Логин"
						/>
					</Form.Item>
					<Form.Item
						name="password"
						rules={[{ required: true, message: 'Пожалуйста введите пароль!' }]}
					>
						<Input
							prefix={<LockOutlined className="site-form-item-icon" />}
							type="password"
							placeholder="Пароль"
						/>
					</Form.Item>

					<Center>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								className="login-form-button"
							>
								Войти
							</Button>
						</Form.Item>
					</Center>
				</Form>
			</FormWrapper>
		</Wrapper>
	);
};
