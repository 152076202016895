import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader, Typography } from 'antd';
import { Wrapper, Content, Image } from './styles';
import { useStorage } from '../../firebase';

const { Title } = Typography;

const StoneLayingSandstone: React.FC = () => {
	const { slsImages } = useStorage();

	const navigate = useNavigate();

	return (
		<Wrapper>
			<PageHeader
				onBack={() => navigate(-1)}
				title="Укладка камня, песчаника"
				subTitle="Plitkoklad"
			/>
			<Content>
				<Typography>
					<Title level={5}>
						<p>
							Натуральный камень является ещё одним прекрасным дополнением
							дизайна территории
						</p>
						<p>
							Он хорошо подходит для пошаговых дорожек, или зон отдыха, для
							установки бассейна и там, где вам не хочется чтобы была плитка.
						</p>
						<p>
							Укладка камня имеет особую специфику и требует профессионального
							подхода. Plitkoklad укладывает камень по проверенной технологии,
							которая включает в себя укладку камня, его утрамбовку, а так же
							его скрепление специальным клеевым составом.
						</p>
						<p>
							Несмотря на то, что природный камень достаточно прочный и тяжёлый,
							мы выполняем из него различные узоры и варианты раскладки.
							Природный камень отличное решение и подчёркивание вашего стиля!
						</p>
					</Title>
					<Image height={400} src={slsImages[0]?.url} />
					<Image height={400} src={slsImages[1]?.url} />
					<Image height={400} src={slsImages[2]?.url} />
				</Typography>
			</Content>
		</Wrapper>
	);
};

export default StoneLayingSandstone;
