import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader, Typography } from 'antd';
import { Wrapper, Content, Image } from './styles';
import { useStorage } from '../../firebase';

const { Title } = Typography;

const LayingPavingSlabs: React.FC = () => {
	const { ioscImages } = useStorage();

	const navigate = useNavigate();

	return (
		<Wrapper>
			<PageHeader
				onBack={() => navigate(-1)}
				title="Ливневые коммуникации, и система водоотведения"
				subTitle="Plitkoklad"
			/>
			<Content>
				<Typography>
					<Title level={5}>
						<p>
							На этом этапе экономить нельзя. Он мало того что очень важен, он
							делается на первых этапах работ, и в случае ошибки монтажа,
							приведёт к трудно исправимым последствиям. Ливневые коммуникации
							нужны, для того чтобы отводить воду с крыши и различных участков в
							одно место
						</p>
						Мы рекомендуем использовать качественные долговечные материалы,
						которые при правильном монтаже будут служить очень долго и выполнять
						свою функцию. Весь просчет и монтаж водоотвода лежит на нас, а у
						заказчика просим определить в каком месте лучше смонтировать колодцы
						или дренаж.
					</Title>
					<Image height={400} src={ioscImages[0]?.url} />
					<Image height={400} src={ioscImages[1]?.url} />
				</Typography>
			</Content>
		</Wrapper>
	);
};

export default LayingPavingSlabs;
