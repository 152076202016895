import { useCallback, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
	getStorage,
	ref as sRef,
	listAll,
	getDownloadURL,
	deleteObject,
} from 'firebase/storage';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import {
	getDatabase,
	ref as dRef,
	set,
	onValue,
	update,
} from 'firebase/database';
import { useLocalStorage } from 'hooks';
import { message } from 'antd';
import { ReviewsT, ReviewsDataT, ImagesT, ImageT, StoreImageT } from './types';
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
	apiKey: 'AIzaSyBjesYskO9Yv8IDcTi3AJNARSe6YGXV_Is',
	authDomain: 'plitkoklad-e6389.firebaseapp.com',
	projectId: 'plitkoklad-e6389',
	storageBucket: 'plitkoklad-e6389.appspot.com',
	messagingSenderId: '116550996747',
	appId: '1:116550996747:web:9256369f40b2e353fe880e',
	measurementId: 'G-3WG1J031V1',
	databaseURL:
		'https://plitkoklad-e6389-default-rtdb.europe-west1.firebasedatabase.app/',
};

export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);

const sortArr = (arr: any[]) =>
	arr.sort((a, b) => {
		if (
			parseInt(a.name.replace(/[^\d]/g, '')) >
			parseInt(b.name.replace(/[^\d]/g, ''))
		) {
			return 1;
		} else {
			return -1;
		}
	});

export function useStorage() {
	const [images, setImages] = useState<ImagesT>([]);
	const [lpsImages, setLpsImages] = useState<ImagesT>([]);
	const [gzdImages, setGzdImages] = useState<ImagesT>([]);
	const [slsImages, setSlsImages] = useState<ImagesT>([]);
	const [ioscImages, setIoscImages] = useState<ImagesT>([]);
	const [iocImages, setIocImages] = useState<ImagesT>([]);

	const storageRef = sRef(storage);
	const storageLPSRef = sRef(storage, 'lps');
	const storageSLSRef = sRef(storage, 'sls');
	const storageGZDRef = sRef(storage, 'gzd');
	const storageIOSCRef = sRef(storage, 'iosc');
	const storageIOCRef = sRef(storage, 'ioc');

	useEffect(() => {
		listAll(storageRef).then((refs) =>
			refs.items.map(async (item) => {
				const url = await getDownloadURL(item);
				setImages((imgs) => [
					...imgs,
					{
						url,
						name: (item as unknown as ImageT)._location.path_,
					},
				]);
			})
		);

		listAll(storageIOCRef).then((refs) =>
			refs.items.map(async (item) => {
				const url = await getDownloadURL(item);
				setIocImages((imgs) => [
					...imgs,
					{
						url,
						name: (item as unknown as ImageT)._location.path_,
					},
				]);
			})
		);

		listAll(storageIOSCRef).then((refs) =>
			refs.items.map(async (item) => {
				const url = await getDownloadURL(item);
				setIoscImages((imgs) => [
					...imgs,
					{
						url,
						name: (item as unknown as ImageT)._location.path_,
					},
				]);
			})
		);

		listAll(storageLPSRef).then((refs) =>
			refs.items.map(async (item) => {
				const url = await getDownloadURL(item);
				setLpsImages((imgs) => [
					...imgs,
					{
						url,
						name: (item as unknown as ImageT)._location.path_,
					},
				]);
			})
		);

		listAll(storageSLSRef).then((refs) =>
			refs.items.map(async (item) => {
				const url = await getDownloadURL(item);
				setSlsImages((imgs) => [
					...imgs,
					{
						url,
						name: (item as unknown as ImageT)._location.path_,
					},
				]);
			})
		);

		listAll(storageGZDRef).then((refs) =>
			refs.items.map(async (item) => {
				const url = await getDownloadURL(item);
				setGzdImages((imgs) => [
					...imgs,
					{
						url,
						name: (item as unknown as ImageT)._location.path_,
					},
				]);
			})
		);

		return () => {
			setImages([]);
			setLpsImages([]);
			setGzdImages([]);
			setSlsImages([]);
			setIoscImages([]);
			setIocImages([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		images,
		lpsImages: sortArr(lpsImages),
		gzdImages: sortArr(gzdImages),
		slsImages: sortArr(slsImages),
		ioscImages: sortArr(ioscImages),
		iocImages: sortArr(iocImages),
	};
}

export function useData() {
	const [reviews, setReviews] = useState<ReviewsT>({
		data: {
			allowed: [],
			canceled: [],
			waiting: [],
		},
		loading: false,
	});

	const db = getDatabase();

	const writeReview = (
		id: string,
		name: string,
		text: string,
		rating: number,
		images: StoreImageT[]
	) => {
		const res = set(dRef(db, `reviews/${id}`), {
			name,
			text,
			rating,
			images,
			status: 'waiting',
		});
		return res;
	};

	const getReviews = useCallback(async () => {
		setReviews((p) => ({ ...p, loading: true }));
		const allReviews = dRef(db, 'reviews');
		onValue(allReviews, (snapshot) => {
			const data = snapshot.val();

			const getDataByTypes = () => {
				const allowed: ReviewsDataT[] = [];
				const canceled: ReviewsDataT[] = [];
				const waiting: ReviewsDataT[] = [];

				const res = { allowed, canceled, waiting };

				if (!data) return res;

				const keys = Object.keys(data);
				keys.forEach((key) => {
					const dataObj = { ...data[key], id: key };
					if (data[key].status === 'allowed') {
						allowed.push(dataObj);
					} else if (data[key].status === 'canceled') {
						canceled.push(dataObj);
					} else if (data[key].status === 'waiting') {
						waiting.push(dataObj);
					}
				});

				return res;
			};

			setReviews((p) => ({ ...p, data: getDataByTypes(), loading: false }));
		});
	}, [db]);

	const changeStatusReview = async (id: string, status: string) => {
		try {
			const review = dRef(db, `reviews/${id}`);
			update(review, {
				status,
			});
		} catch (e) {
			message.error('Произошла ошибка, повторите попытку');
		}
	};

	const deleteReview = async (id: string, paths: string[]) => {
		try {
			paths.forEach(async (path) => {
				const imgRef = sRef(storage, path);
				await deleteObject(imgRef);
			});

			await set(dRef(db, `reviews/${id}`), null);
		} catch (e) {
			message.error('Произошла ошибка, повторите попытку');
		}
	};

	useEffect(() => {
		getReviews();
	}, [getReviews]);

	return { deleteReview, changeStatusReview, writeReview, reviews };
}

export function useAuth() {
	const auth = getAuth();
	const navigate = useNavigate();
	const { saveUser, dropUser, isUser } = useLocalStorage();

	const signIn = (email: string, password: string) =>
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				const user = userCredential.user;

				if (!isUser()) {
					saveUser(user.uid);
				}

				navigate('/admin');
			})
			.catch(() => {
				message.error('Ошибка входа, повторите попытку!');
			});

	const signOut = () => {
		if (isUser()) {
			dropUser();
		}

		navigate('/auth');
	};

	return { signIn, signOut };
}
