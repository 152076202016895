import styled from 'styled-components';
import { devices } from 'styles';

export const Wrapper = styled.div`
	height: 15%;

	@media ${devices.mobile} {
		padding-top: 5px;
	}
	@media ${devices.tablet} {
		padding-top: 5px;
	}
	@media ${devices.laptop} {
		padding-top: 10px;
	}
`;

export const Block = styled.div`
	@media ${devices.mobile} {
		width: 100%;
	}
	@media ${devices.tablet} {
		width: 100%;
	}
	@media ${devices.laptop} {
		width: 100%;
	}

	height: 100%;
	background-color: #fff;
	padding: 15px;

	:hover {
		box-shadow: 2px 2px 20px #000;
	}
`;

export const Title = styled.div`
	height: 15%;
	font-family: Bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 25px;
	line-height: 30px;
	color: #000;
`;

export const Flex = styled.div`
	width: 100%;
	height: 85%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const EmptyWrapper = styled.div`
	margin: 1%;
`;

export const CommentWrapper = styled.div`
	flex-basis: 90%;
	display: flex;
	justify-content: space-between;
`;

export const IconWrapper = styled.div<{ pointer?: boolean }>`
	flex-basis: 5%;
	display: flex;
	justify-content: center;

	:first-child {
		transform: scale(2);
	}
	:last-child {
		transform: scale(2);
	}

	${(p) => p.pointer && 'cursor: pointer;'}
`;

export const TextName = styled.span`
	font-family: Bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 25px;
	color: #000;
`;
