import styled from 'styled-components';
import { devices } from 'styles';
import { Form as AntForm } from 'antd';

export const Wrapper = styled.div`
	height: 30%;

	@media ${devices.mobile} {
		margin-top: 5px;
	}
	@media ${devices.tablet} {
		margin-top: 5px;
	}
	@media ${devices.laptop} {
		margin-top: 0;
		padding-top: 10px;
	}
`;

export const Block = styled.div`
	height: 100%;
	background-color: #fff;
	width: 100%;
	padding: 10px;

	:hover {
		box-shadow: 2px 2px 20px #000;
	}
`;

export const Title = styled.div`
	font-family: Bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 25px;
	line-height: 30px;
	color: #000;
	text-align: center;
`;

export const FormWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 15px 0 0 0;
`;

export const Form = styled(AntForm)`
	width: 90%;
`;
