import React from 'react';
import LogoIcon from './svg/logo.svg';
import {
	Wrapper,
	Img,
	Title,
	MediaFlex,
	Flex,
	FlexColumn,
	Text,
} from './styles';

export const Header: React.FC = () => {
	return (
		<Wrapper>
			<MediaFlex>
				<Flex>
					<Img src={LogoIcon} alt="logo" loading="lazy" />
					<Title>Plitkoklad</Title>
				</Flex>
				<FlexColumn>
					<Text borderBottom>
						Благоустройство территории и укладка тротуарной плитки
					</Text>
					<Text small>В Минске и Минском районе</Text>
				</FlexColumn>
			</MediaFlex>
		</Wrapper>
	);
};
