import styled, { keyframes } from 'styled-components';

const gradient = keyframes`{
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}`;

const wave1 = keyframes`{
	50% {
		transform: rotate(180deg);
		width: 60px;
		height: 63px;
	}
	75% {
		width: 65px;
	}
	100% {
		transform: rotate(360deg);
		width: 50px;
		height: 50px;
	}
}`;

const wave2 = keyframes`{
	100% {
		transform: rotate(360deg);
	}
}`;

export const Wrapper = styled.div`
	z-index: 1;
	width: 80px;
	height: 80px;
	position: fixed;
	bottom: 0;
	right: 0;
`;

const Wave = styled.div`
	position: absolute;
	width: 50px;
	height: 50px;
`;

export const Wave1 = styled(Wave)`
	left: -7px;
	top: -5px;
	background-color: #a0e9ff;
	border-radius: 20px;
	animation: ${wave1} 7s linear infinite;
`;

export const Wave2 = styled(Wave)`
	left: 0;
	top: 0;
	background-color: #57d0ff;
	border-radius: 20px;
	animation: ${wave2} 4s linear infinite;
`;

export const Icon = styled(Wave)`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

export const MenuWrapper = styled(Wave)`
	height: auto;
	left: 0;
	top: -200px;
	background-color: #fff;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const MenuIcon = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	height: 50px;
	width: 50px;

	:nth-child(1) {
		border-radius: 20px 20px 0 0;
	}

	:nth-child(1),
	:nth-child(2),
	:nth-child(3),
	:nth-child(4) {
		background: radial-gradient(#fff, #a0e9ff);
		background-size: 400% 400%;
		animation: ${gradient} 15s ease infinite;
	}

	:nth-child(1):hover,
	:nth-child(2):hover,
	:nth-child(3):hover,
	:nth-child(4):hover {
		background: #fff;
	}

	:nth-child(1):active,
	:nth-child(2):active,
	:nth-child(3):active,
	:nth-child(4):active {
		background: #ccc;
	}

	:last-child {
		background: #57d0ff;
		border-radius: 0 0 20px 20px;
	}

	:last-child:hover {
		background: #fff;
	}

	:last-child:active {
		background: #ccc;
	}
`;
