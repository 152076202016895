import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader, Typography, Divider } from 'antd';
import { Wrapper, Content, Image } from './styles';
import { useStorage } from '../../firebase';

const { Title, Text } = Typography;

const LayingPavingSlabs: React.FC = () => {
	const { lpsImages } = useStorage();

	const navigate = useNavigate();

	return (
		<Wrapper>
			<PageHeader
				onBack={() => navigate(-1)}
				title="Укладка тротуарной плитки"
				subTitle="Plitkoklad"
			/>
			<Content>
				<Typography>
					<Image height={400} src={lpsImages[0]?.url} />
					<Title level={5}>
						Тротуарная плитка является удобным и красивым стилем вашей
						территории! Она требует профессионального подхода и качества, так
						как делается на длительное время. Бригада Plitkoklad занимается
						своим делом уже много лет, мы укладываем плитку по проверенным
						передовым технологиям, которые обеспечивают долговечность, и
						красоту! В этой рубрике будет часть  информации как мы производим
						укладку тротуарной плитки для тех, кто хочет быть в курсе всего,
						вместе с нами!
					</Title>
					<Divider />
					<Title level={5}>1. Первый этап в укладке плитки это разметка.</Title>
					<Text>
						Для того чтобы расставить оптимальные и нужные значения высоты и
						границ, мы используем профессиональные оптические приборы и уровни,
						у которых минимальная погрешность сведена к самому минимуму. Стоит
						отметить что разметка играет важную роль в укладке плитки, поскольку
						неправильно установленные значения могут привести к самым неприятным
						последствиям.
					</Text>
					<div>
						<Image height={400} src={lpsImages[1]?.url} />
						<Image height={400} src={lpsImages[2]?.url} />
					</div>
					<Divider />
					<Title level={5}>
						2. Этап выемки грунта и отсыпка песчано-гравийной смесью, или
						гравием, так же очень важный этап в укладке плитки
					</Title>
					<Text>
						Plitkoklad работает с различной техникой, которой управляют
						профессиональные операторы с большим стажем, бывают случаи когда
						подъезда для техники нет, тогда мы аккуратно выбираем грунт вручную.
						Здесь важно выбрать оптимальную глубину выемки, и отсыпать
						качественный материал подходящий для вашей территории. Мы заботимся
						о том, чтобы рассчитать необходимое количество нужного материала,
						исходя из того для чего предназначается определённый участок с
						плиткой. Мы так же занимаемся регулировкой техники по участку и
						вовремя заказываем материалы, и это экономит ваши деньги.
					</Text>
					<div>
						<Image height={200} src={lpsImages[3]?.url} />
						<Image height={200} src={lpsImages[4]?.url} />
					</div>
					<Divider />
					<Title level={5}>
						3. Этап утрамбовки и выравнивания рабочей площади.
					</Title>
					<Text>
						Этот этап очень важен в укладке плитки, от этого будет зависеть
						насколько крепко и ровно лежать плитка. Plitkoklad Использует разные
						вибротрамбовки для уплотнения ПГС, добиваясь максимально
						утрамбованной поверхности. В среднем значении мы трамбуем около 6
						раз рабочую поверхность, а иногда больше, мы уделяем особое значение
						утрамбовке, так как это играет важную роль в укладке плитки. Так же
						мы выравниваем поверхность по рейкам, и снова трамбуем, это нужно
						для того чтобы плитка лежала красиво и ровно.
					</Text>
					<div>
						<Image height={400} src={lpsImages[5]?.url} />
						<Image height={400} src={lpsImages[6]?.url} />
						<Image height={400} src={lpsImages[7]?.url} />
					</div>
					<Divider />
					<Title level={5}>
						4. Этот этап предусматривает собой выбор варианта, или рисунок
						укладки плитки. Создание узора.
					</Title>
					<Text>
						Достаточно показать нам картинку с желаемым вариантом укладки и мы
						воплотим его в реальность! Так же при необходимости мы помогаем с
						выбором рисунка, и делаем эстетически подходящие рекомендации. А ещё
						Plitkoklad выполнит любой узор или рисунок на вашей плитке, который
						вам понравится. Мы используем профессиональный инструмент для
						качественного запила плитки и создания узора Мы стараемся
						максимально воплотить ваши ожидания в реальность, для того чтобы вы
						долгие годы были довольны, обратившись к нам.
					</Text>
					<div>
						<Image height={250} src={lpsImages[8]?.url} />
						<Image height={250} src={lpsImages[9]?.url} />
						<Image height={250} src={lpsImages[10]?.url} />
						<Image height={250} src={lpsImages[11]?.url} />
						<Image height={250} src={lpsImages[12]?.url} />
					</div>
					<Divider />
					<Title level={5}>
						5. Стяжка из цементно-песчаной смеси, и укладка тротуарной плитки.
					</Title>
					<Text>
						Стяжка делается из сухой смеси песка и цемента, она так же
						трамбуется, и тянется по рейкам, опять же для того, чтобы
						поверхность была ровной мы используем соответствующие инструменты и
						выравниваем смесь. После этого мы укладываем тротуарную плитку.
						Плитка имеет минимальную разбежку по высоте, и поэтому заботясь о
						ровной площадке после укладки плитки, мы снова её трамбуем и
						добиваемся ровной поверхности
					</Text>
					<div>
						<Image height={400} src={lpsImages[13]?.url} />
						<Image height={400} src={lpsImages[14]?.url} />
					</div>
					<Divider />
					<Title level={5}>6. Засыпка швов песком.</Title>
					<Text>
						Засыпка швов делается из мелкозернистого песка, для что того чтобы
						плитка не могла окончательно сместиться с места, и чтобы в швы не
						засыпались другие ненужные ингредиенты, такие как грязь, соль. и т.д
					</Text>
					<div>
						<Image height={400} src={lpsImages[15]?.url} />
					</div>
					<Divider />
					<Title level={5}>
						После этих процедур плитка будет радовать ваши глаза, и готова к
						использованию. Мы заботимся о том чтобы вы всегда были довольны
						результатом, и сроками выполнения укладки. Всегда стараемся учесть
						предпочтения заказчика, и получить очередной хороший отзыв в
						копилку!
					</Title>
				</Typography>
			</Content>
		</Wrapper>
	);
};

export default LayingPavingSlabs;
