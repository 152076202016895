import React from 'react';
import { Wrapper, Title, Flex, Text, InfoText } from './styles';

export const Footer: React.FC = () => {
	return (
		<Wrapper>
			<Flex>
				<Title>Plitkoklad</Title>
				<div>
					<Text>В Минске и Минском районе</Text>
				</div>
				<div>
					<InfoText>ИП Федорук</InfoText>
				</div>
			</Flex>
		</Wrapper>
	);
};
