import { useState } from 'react';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useData, storage } from '../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { StoreImageT } from '../firebase/types';

type StatusT = {
	loading: boolean;
	sent: boolean;
};

export function useAddReview() {
	const [status, setStatus] = useState<StatusT>({
		loading: false,
		sent: false,
	});

	const { writeReview } = useData();

	const addReview = async (
		name: string,
		text: string,
		rate: number,
		file?: File
	) => {
		const id = uuidv4();
		const images: StoreImageT[] = [];

		try {
			setStatus((prevStatus) => ({ ...prevStatus, loading: true }));

			if (file) {
				const fileName = `images/${Date.now()}-${file?.name}`;
				const storageRef = await ref(storage, fileName);

				await uploadBytesResumable(storageRef, file);

				const url = await getDownloadURL(storageRef);
				images.push({
					url,
					path: fileName,
					uploadedAt: Date.now().toString(),
				});
			}

			await writeReview(id, name, text, rate, images);
			await fetch(`https://plitkoklad-bot.onrender.com/review?name=${name}`, {
				headers: {
					Accept: 'application/json',
				},
				method: 'GET',
			});

			setStatus((prevStatus) => ({ ...prevStatus, sent: true }));
		} catch (e) {
			message.error('Произошла ошибка, повторите отправку');
		}

		setStatus((prevStatus) => ({ ...prevStatus, loading: false }));
	};

	return { addReview, status };
}
