import styled from 'styled-components';
import { devices } from 'styles';

export const Wrapper = styled.div`
	height: 50%;

	@media ${devices.mobile} {
		padding-top: 5px;
	}
	@media ${devices.tablet} {
		padding-top: 5px;
	}
	@media ${devices.laptop} {
		padding-top: 10px;
	}
`;

export const Content = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	padding: 5px;

	:hover {
		box-shadow: 2px 2px 20px #000;
	}
`;
